import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const BahaiGemeindeDeutschland = ({ data }) => (
    <InnerPage
        title="Die Bahá’í in Deutschland"
        description="Beginn und Entwicklung der Bahá’í-Gemeinde in Deutschland"
    >
        <IntroHolder
            title="Die Bahá’í in Deutschland"
            subtitle="Beginn und Entwicklung der Bahá’í-Gemeinde in Deutschland"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Gemeinsames Handeln',
                'current-item': 'Die Bahá’í in Deutschland',
            }}
        >
            <IntroQuote>
                O wie glücklich waren die Tage Meines Aufenthalts in Stuttgart,
                und wie strahlend waren die Zusammenkünfte mit den Freunden.
                [...] Wann immer ich mich dieser herrlichen Augenblicke
                erinnere, ist mein Herz und meine Seele von Freude erfüllt.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Die Idee des Weltfriedens und der Einheit zwischen den
                    Menschen, gleich welcher Herkunft, Religion oder welchen
                    Geschlechts, fand in der deutschen Bahá’í-Gemeinde bereits
                    an der Wende zum 20. Jahrhundert und insbesondere um die
                    Zeit des Ersten Weltkriegs großen Anklang.
                </p>
                <div>
                    <h2>Frühre Jahre</h2>

                    <p>
                        1905 zog Dr. Edwin Fischer, ein Deutsch-Amerikaner, von
                        den USA nach Stuttgart – er war damit der erste Bahá’í
                        in Deutschland. Kurz darauf bildete sich eine erste
                        Gruppe von Bahá’í in der Stuttgarter Region. In den
                        folgenden Jahren erschienen die ersten Bahá’í-Schriften
                        auf Deutsch, darunter die „Verborgenen Worte“.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage1.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        1913 besuchte ‘Abdul’-Bahá Deutschland und rief in
                        öffentlichen Vorträgen und Veranstaltungen zu einem
                        friedvollen und vorurteilsfreien Zusammenleben auf.
                        Viele Menschen fühlten sich davon berührt und angezogen,
                        sodass eine Reihe von örtlichen Bahá’í-Gruppen entstand.
                    </p>
                    <p>
                        1923 wählten die Bahá’í in Deutschland und Österreich
                        gemeinsam ihr erstes nationales Vertretungsorgan, den
                        Nationalen Geistigen Rat, als eine der weltweit ersten
                        landesweit organisierten Bahá’í-Gemeinden.
                    </p>
                    <p>
                        Unter dem NS-Regime erlebten deutsche Bahá’í zunächst
                        großes Misstrauen, schließlich kamen direkte
                        Diskriminierungen und Drangsalierungen hinzu, bis die
                        Bahá’í-Religion 1937 verboten wurde. Sämtliche Bestände
                        sowie Bücher und Dokumente wurden beschlagnahmt,
                        Mitglieder der Gemeinde inhaftiert, verhört und zu Geld-
                        und Gefängnisstrafen verurteilt.
                    </p>

                    <h2>Wiederaufnahme des Gemeindelebens nach 1945</h2>

                    <p>
                        Nach dem Ende des Zweiten Weltkrieges konnten lediglich
                        die Bahá’í Westdeutschlands ihre Aktivitäten wieder voll
                        aufnehmen. Im Osten Deutschlands galt ab 1948 ein
                        erneutes Organisationsverbot für die Gemeinde, während
                        im Westen die Bahá’í im April 1946 wieder ihren
                        Nationalen Geistigen Rat wählen konnten. Ein Höhepunkt
                        der Folgejahre war die Einweihung des Europäischen
                        Hauses der Andacht im Jahr 1964 in Hofheim-Langenhain im
                        Taunus.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage2.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Das UN-Friedensjahr 1986 fand auch in der
                        bundesdeutschen Bahá’í-Gemeinde ein breites Echo mit
                        zahlreichen Vorträgen, Friedensfesten,{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/andachtsversammlungen/">
                            Andachten
                        </Link>{' '}
                        und Konzerten. Im selben Jahr veröffentlichte das
                        Universale Haus der Gerechtigkeit eine Friedensbotschaft
                        unter dem Titel „Die Verheißung des Weltfriedens“. Sie
                        war – auf der Grundlage der Schriften{' '}
                        <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                            Bahá’u’lláhs
                        </Link>{' '}
                        – ein Aufruf an die Führer der Welt, den Krieg für alle
                        Zeiten zu verbannen. Die Schrift wurde mit vielen
                        Menschen geteilt und auch deutschen Würdenträgern wie
                        Willy Brandt und Johannes Rau überreicht.
                    </p>

                    <h2>Lebendige Gemeinde in einem geeinten Deutschland</h2>

                    <p>
                        Der Mauerfall 1989 wurde auch zu einer Wende in der
                        deutschen Bahá’í-Geschichte. Das noch formell bestehende
                        Verbot wurde im Frühjahr 1990 durch den Ministerrat der
                        DDR aufgehoben. Nunmehr gab es wieder eine geeinte
                        Bahá’í-Gemeinde in Deutschland. Die Bildung von
                        Örtlichen Geistigen Räten begann mit der Wahl in
                        Leipzig, wo bereits vor dem Krieg eine rege
                        Bahá’í-Gemeinde bestand.
                    </p>
                    <p>
                        Seit den 90er Jahren engagieren sich Bahá’í-Gemeinden
                        zunehmend im{' '}
                        <Link to="/gemeinsames-handeln/beteiligung-an-diskursen/">
                            interreligiösen Dialog
                        </Link>{' '}
                        und in anderen in der Öffentlichkeit diskutierten Themen
                        wie Gleichberechtigung, soziale Fragen,
                        Religionsfreiheit oder Integration.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage3.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Das Europäische{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/das-konzept-haus-der-andacht/">
                            Haus der Andacht
                        </Link>{' '}
                        in Hofheim-Langenhain entwickelte sich zum Ort der
                        interreligiösen Begegnung. In seinem Schatten machte
                        sich das „Forum Langenhain‘‘ seit den 1990er Jahren für
                        über zwei Jahrzehnte als öffentlicher Gesprächsraum in
                        der Nachbarregion einen Namen. Seit 1995 wird dort auch
                        das heute noch jährlich stattfindende
                        „Bahá’í-Sommerfest“ gefeiert, das weit über tausend
                        Menschen aus nah und fern anzieht. Der Chor „Stimmen
                        Bahás“ kann bis zum heutigen Tag bundesweit viele
                        Mitwirkende für sich gewinnen.
                    </p>

                    <h2>Nach der Jahrtausendwende</h2>

                    <p>
                        Das Europäische Haus der Andacht wurde mehrfach zum Ort
                        bundesweiter Begegnungen über Menschenrechte,
                        Religionsfreiheit, Gleichberechtigung von Mann und Frau
                        oder Antirassismus.
                    </p>
                    <p>
                        Im Jahr 2013 wurde der Bahá’í-Gemeinde in Deutschland
                        durch das Land Hessen der Rechtsstatus einer
                        Körperschaft des öffentlichen Rechts verliehen.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage4.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Heute leben rund 6000 Bahá’í in vielen Städten und
                        Gemeinden Deutschlands. Sie sind als Gruppen oder
                        Gemeinden in der Bahá’í-Gemeinde in Deutschland K.d.ö.R.
                        organisiert und wählen ihre Vertreter in regelmäßigen
                        Abständen. Zu den wichtigsten Aktivitäten lokaler
                        Gemeinden und Gruppen zählen:{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/andachtsversammlungen/">
                            Andachtsversammlungen
                        </Link>
                        ,{' '}
                        <Link to="/gemeinsames-handeln/jugend/arbeit-mit-juniorjugendlichen/">
                            Jugendarbeit
                        </Link>
                        ,{' '}
                        <Link to="/gemeinsames-handeln/familie-und-kinder/kinderklasse/">
                            Kinderklassen
                        </Link>
                        ,{' '}
                        <Link to="/gemeinsames-handeln/gemeinsames-lernen/das-trainingsinstitut/">
                            Studienkreise
                        </Link>{' '}
                        und{' '}
                        <Link to="/bahai-kennenlernen/">
                            Gemeindeaktivitäten
                        </Link>{' '}
                        wie das Neunzehntagefest oder Bahá’í-Feiertage.
                    </p>
                    <p>
                        Auf bundesweiter Ebene ist die Bahá’í-Gemeinde in
                        Deutschland Mitglied beim „Abrahamischen Forum“, „Runden
                        Tisch der Religionen“, dem „Forum Menschenrechte“, dem
                        „Deutschen Menschenrechtsfilmpreis“ und im Trägerverein
                        des „Deutschen Instituts für Menschenrechte“.
                    </p>
                    <p>
                        Angehörige der deutschen Bahá’í- Gemeinde engagieren
                        sich in ihren Nachbarschaften mit Freunden und Bekannten
                        für ein offenes, friedliches und solidarisches
                        Miteinander. Sie nehmen an verschiedenen{' '}
                        <Link to="/gemeinsames-handeln/beteiligung-an-diskursen/">
                            gesellschaftlichen Diskursen
                        </Link>{' '}
                        teil, wie z.B. über Menschenrechte, Abbau von
                        Vorurteilen und Rassismus, Migration, Naturschutz etc.
                    </p>
                </div>
                <Reference>
                    <p>1. ʻAbdu’l-Bahá, aus einem Brief vom 6. März 1913</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default BahaiGemeindeDeutschland;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "die-bahai-in-deutschland-feature.jpg" }
        ) {
            ...fluidImage
        }
        collage1: file(
            relativePath: { eq: "gemeinde-in-deutschland-collage-1.png" }
        ) {
            ...fluidImage
        }
        collage2: file(
            relativePath: {
                eq: "Wiederaufnahme-des-Gemeindelebens-nach-1945-768x614.png"
            }
        ) {
            ...fluidImage
        }
        collage3: file(relativePath: { eq: "Lebendige-Gemeinden.png" }) {
            ...fluidImage
        }
        collage4: file(
            relativePath: { eq: "Nach-der-Jahrtausendwende-mit-Text.png" }
        ) {
            ...fluidImage
        }
    }
`;
